@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');
body {
  margin: 0;
   font-family: "Roboto", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.navbar{
  background-color: rgb(199, 8, 8) !important;
}

.nav-link{
  color: aliceblue !important;
}

.navbar-brand{
  color: aliceblue;
}

.navbar-nav{
  margin-left: auto ;
}

.smallimg{
  height: 200px;
  width:100% !important;
  border-radius: 5px;;
}

h1{
  font-size: 20px;;
}

.btn{
  background-color: rgb(199, 8, 8) !important;
  color:aliceblue;
  box-shadow: none !important;
}

.bs{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 20px;
  border-radius: 5px;
  padding: 10px;
}
.cover-image-container {
  position: relative;
  height: 600px;
  width: 100%;
  overflow: hidden;
}

.cover-image {
  width: 120%;
  height: 120%;
  object-fit: cover;
  object-position: center;
}

.cover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  padding: 20px;
}

.img-fluid {
  border-radius: 10px;
}

.service-image {
  max-width: 400px;
  max-height: 250px;
}

.playground-image {
  max-width: 400px;
  max-height: 250px;
}

.food-image {
  max-width: 400px;
  max-height: 250px;
}

.exercise-image {
  max-width: 400px;
  max-height: 250px;
}

.grooming-image {
  max-width: 500px;
  max-height: 450px;
}

.rest-image {
  max-width: 400px;
  max-height: 250px;
}

h1, h2 {
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}


.footer{
  background-color: rgb(199, 8, 8) !important;
  color: aliceblue;
  align-items: center;
}

.MuiDialog-paper {
  border-radius: 12px;
}
